<template>
    <div>
        <div v-if="isDivision">
            <DivisionDashboard></DivisionDashboard>
        </div>
        <v-content v-else>
            <!--<v-breadcrumbs :items="items" large></v-breadcrumbs>-->
            <v-row class="mt-3">
                <v-col cols="12">
                    <v-row justify="start">
                        <v-col
                                cols="2"
                                v-for="title in titles"
                                v-bind:key="title.link"
                                class="viewCard"
                        >
                            <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                    <router-link :to="title.link">
                                        <HoverCard :cardData="title"></HoverCard>
                                        <!--<v-card
            :elevation="10"
            class="blue darken-1 text-center"
            height="100"
            width="200"
    >
        <v-list-item three-line>
            <v-list-item-content>
                <v-list-item-title
                        class="white--text subtitle-1 mt-1 pa-4"
                >{{title.name}}
                </v-list-item-title>
                <v-list-item-subtitle class="white--text header-6 mt-1">
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </v-card>-->
                                    </router-link>
                                </template>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-container>
                <v-row>
                    <v-col cols="12" md="6">
                        <h1 v-if="!isNepaliLanguage"
                            class="headline mb-2 mt-5 primary--text">
                            {{$t('employee')}}
                        </h1>
                        <v-card>
                            <v-list style="background-color: #1976d2">
                                <v-list-item>
                                    <v-list-item-avatar>
                                        <v-title id="table" class="white--text"><strong>{{ $t("s_no") }}</strong></v-title>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title id="table"
                                                           align="center"
                                                           class="white--text">
                                            <strong>{{ $t("employee_name") }}</strong>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="center">
                                        <v-list-item-title id="table" class="mr-18 white--text"><strong>{{ $t("email") }}</strong></v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="center">
                                        <v-list-item-title id="table" class="white--text">
                                            <strong>
                                                {{$t("position")}}
                                            </strong>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="right">
                                        <v-list-item-title id="table" class="white--text">
                                            <strong>
                                                {{$t("contact_no")}}
                                            </strong>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                        <v-card style="overflow-y: scroll; height: 350px">
                            <v-card style="height: 350px;">
                                <v-list v-for="(hotelEmployee, i) in hotelEmployeeDetails"
                                        :key="i">
                                    <v-list-item :style="{ background: hotelEmployee.backgroundColor }">
                                        <v-list-item-avatar>
                                            <v-title id="table" class="black--text">
                                                {{i + 1}}
                                            </v-title>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title id="table"
                                                               align="center"
                                                               class="black--text">{{ hotelEmployee.empName }}</v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-content align="center">
                                            <v-list-item-title id="table"
                                                               class="mr-18 black--text">{{ hotelEmployee.empEmail }}</v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-content align="center">
                                            <v-list-item-title id="table" class="black--text">
                                                {{ hotelEmployee.empType }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-content align="right">
                                            <v-list-item-title id="table" class="black--text">
                                                {{hotelEmployee.empPhone}}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider></v-divider>
                                </v-list>
                            </v-card>
                        </v-card>
                        <!--<button @click="$router.push('/hotelEmployee')" class=" white--text primary align-end" dark style="padding:5px; border-radius:5px; margin-top:10px;">For More Details</button>-->
                    </v-col>
                    <v-col cols="12" sm="6">
                        <h1 v-if="!isNepaliLanguage"
                            class="headline mb-2 mt-5 primary--text">
                            {{$t('bar_graph')}}
                        </h1>
                        <v-card class="pa-5" elevation="5">
                            <h1 class="headline mb-2 primary--text"
                                style="border-bottom: 1px solid grey">
                                {{$t('enrolled_students_faculty_wise')}}
                            </h1>
                            <template>
                                <div id="app">
                                    <GChart type="ColumnChart"
                                            :data="enrolledStudentsBarGraphAsync"
                                            :options="chartOptions"
                                            style="height: 350px" />
                                </div>
                            </template>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
            <v-container>
                <v-row>
                    <v-col cols="12" sm="12">
                        <v-card class="pa-5" elevation="5">
                            <h1 class="headline mb-2 primary--text"
                                style="border-bottom: 1px solid grey">
                                {{$t('yearly_monthly_enrolled_students')}}
                            </h1>
                            <template>
                                <div id="app">
                                    <GChart type="ColumnChart"
                                            :data="monthlyData"
                                            :options="chartOptions"
                                            style="height: 350px" />
                                </div>
                            </template>
                        </v-card>

                    </v-col>
                </v-row>
            </v-container>
            <v-container fluid>
                        <v-expansion-panels hover>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="subtitle-1 text-capitalize" style="background-color:rosybrown">
                                    {{$t('filter')}}
                                </v-expansion-panel-header><br/>
                                <v-expansion-panel-content>
                                    <v-row align="center">
                                        <v-col cols="12" sm="6">
                                            <v-autocomplete v-model="serverParams.FacultyID"
                                                            :items="facultyType"
                                                            item-value="id"
                                                            item-text="value"
                                                            :label="$t('select_faculty')"
                                                            clearable outlined dense>

                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-autocomplete v-model="serverParams.Condition"
                                                            :items="type"
                                                            item-value="value"
                                                            item-text="value"
                                                            :label="$t('select_type')"
                                                            clearable outlined dense>
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    <v-row align="center" justify="center">
                                        <v-btn color="primary" dark v-on:click="searchInput" class="mr-1">
                                            <i class="material-icons">search</i>{{$t('search')}}
                                        </v-btn>
                                        <v-btn color="rgb(157,25,25)" dark @click="clearInput">
                                            <i class="material-icons">clear</i>{{$t('clear')}}
                                        </v-btn>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-card class="pa-5" elevation="5">
                                    <h1 class="headline mb-2 primary--text"
                                        style="border-bottom: 1px solid grey">
                                        {{$t('students_detail')}}
                                    </h1>
                                    <template style="height:500px">
                                        <GChart type="PieChart"
                                                :options="options"
                                                :data="studentsDetailBarGraphAsync"
                                                height="10000"
                                                width="10000"
                                                align="center" />
                                    </template>
                                    <!--<template>
                                        <div id="app">
                                            <GChart type="ColumnChart"
                                                    :data=""
                                                    :options="chartOptions"
                                                    style="height: 380px" />
                                        </div>
                                    </template>-->
                                </v-card>

                            </v-col>
                        </v-row>
            </v-container>
        </v-content>
    </div>
</template>

<script>
    import HoverCard from '@/components/Basic/HoverCard'
    import axios from "axios";
    import {mapGetters} from 'vuex'
    import DivisionDashboard from '../../dashboard/DivisionDashboard'
    import publicAxios from "../../../simple-axios"
    import { parseJwt } from "@/mixins/parseJwt";

    export default {
        name: "Dashboard",
        mixins: [parseJwt],
        watch: {
            "serverParams.FacultyID": {
                handler: function (val) {
                    if (val) {
                        this.getStudentDetailBar();
                    }
                },
            },
            dialog(val) {
                val || this.close();
            },
            success: {
                handler: function (val, oldVal) {
                    console.log(val, oldVal);
                    this.loadItems();
                },
                deep: true
            }
        },
        computed: {
            ...mapGetters(['getSystemUserData']),
            isDivision() {
                return this.getSystemUserData.role === 'Division'
            },
            titles() {
                return [
                    //{
                    //    name: this.$t('generate_report'),
                    //    link: "/report"
                    //},
                    {
                        name: this.$t('profile'),
                        link: "/branch",
                        icon:"mdi-robot"
                    },
                    {
                        name: this.$t('academics_data'),
                        link: "/AcademicsList",
                        icon: "mdi-database"
                    },
                    {
                        name: this.$t('internship_data'),
                        link: "/InternshipsList",
                        icon: "mdi-database"
                    },
                    {
                        name: this.$t('training_data'),
                        link: "/TrainingsList",
                        icon: "mdi-database"
                    },
                    {
                        name: this.$t('employee_details'),
                        link: "/AcademicEmployee",
                        icon: "mdi-newspaper"
                    }
                    ]
            }
        },
        components: { DivisionDashboard, HoverCard },
        data() {
            return {
                hotelEmployeeDetails: [],
                facultyType: [
                    { id: "1", value: "Academic" },
                    { id: "2", value: "Training" },
                    { id: "3", value: "Internship" }
                ],

                type: [
                    { id: "1", value: "Passed" },
                    { id: "2", value: "Failed" },
                    { id: "3", value: "Left" },
                    { id: "4", value: "Pending" }],
                items: [
                    {
                        text: "Dashboard",
                        disabled: true,
                        to: "/AcademicDashboard",
                        exact: true
                    },
                ],
                serverParams: {
                    search: ""
                },
                branchInformation: [],
                enrolledStudentsBarGraphAsync: [],
                studentsDetailBarGraphAsync: [],
                roomCount: ""
            }
        },
        mounted() {
            this.formData()
            this.getEmployeeDetails()
            this.getTotalStudentFacultyWiseBar()
            this.getYearlyEnrolledStudentPerformance()
            this.getStudentDetailBar()
        },
        methods: {
            async formData() {
                const availableCount = await axios.get('Hotel/RoomStatusCountAsync', {
                    params: {
                        ShowCount: true
                    }
                })
                this.roomCount = availableCount.data
            },
            async getEmployeeDetails() {
                const { data } = await publicAxios.post(
                    "NRBExchange/GetEmployeeDetails/0"
                );
                this.hotelEmployeeDetails = data;
                console.log("this.hotelEmployeeDetails", this.hotelEmployeeDetails);
            },
            async getYearlyEnrolledStudentPerformance() {
                const { data } = await publicAxios.get('Academic/GetEnrolledStudentYearlyCountAsync')
                let formatedData = this.getChartData(data)
                this.monthlyData = formatedData
                console.log('this.getBarAirportMonthlyPerformance', this.monthlyData)
            },
            searchInput() {
                this.getStudentDetailBar();

            },
            clearInput() {
                this.serverParams.FacultyID = null;
                this.serverParams.Condition = null;
            },
            async getTotalStudentFacultyWiseBar() {
                const { data } = await publicAxios.get(
                    "Academic/GetEnrolledStudentsBarGraphAsync"
                );
                console.log(
                    "this.jaa",
                    data
                );



                let formatedData = this.getChartData(data);
                this.enrolledStudentsBarGraphAsync = formatedData;
                console.log(
                    "this.enrolledStudentsBarGraphAsync",
                    this.enrolledStudentsBarGraphAsync
                );
            },

            //async getStudentDetailBar() {
            //    const { data } = await publicAxios.get(
            //        "Academic/GetStudentDetailBarGraphAsync");
            //    let formatedData = this.getChartData(data);
            //    this.studentsDetailBarGraphAsync = formatedData;
            //    console.log(
            //        "this.studentsDetailBarGraphAsync",
            //        this.studentsDetailBarGraphAsync
            //    );
            //},
           async getStudentDetailBar() {
                    this.isLoading = true
                    console.log('heree', this.serverParams.FacultyID)

                    let param = {
                        
                        facultyID: this.serverParams.FacultyID != null && this.serverParams.FacultyID != undefined ? parseInt(this.serverParams.FacultyID) : 0,
                        condition: this.serverParams.Condition,
                    };

                   await axios.post('Academic/GetStudentDetailBarGraphAsync', param)
                        .then(response => {
                            
                            this.totalTouristVisitBarList = response.data
                            let formatedData = this.getChartData(this.totalTouristVisitBarList);
                            this.studentsDetailBarGraphAsync = formatedData;
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    console.log('yaa', this.studentsDetailBarGraphAsync)
            },

            getChartData(data) {
                console.log(data);
                let arr = [];
                arr[0] = Object.keys(data[0]);
                for (let i = 1; i < data.length + 1; i++) {
                    arr[i] = Object.values(data[i - 1]);
                }
                return arr;
            },
        }
        };
</script>

<style lang="scss">
    a {
        text-decoration: none;
    }

</style>